<template>
  <div>
    <SettingsTable :tabSelected="'manual'"></SettingsTable>
  </div>
</template>
<script>
import SettingsTable from "@/views/specialists/sub-modules/financial-approval/views/settings/views/components/SettingsTable.vue"
export default {
  components: {
    SettingsTable,
  },
}
</script>
